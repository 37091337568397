@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0 auto;
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  font-family: monospace;
  justify-content: center;
  background-color: #1d212b;
}

.backlight {
  position: relative;
  transform-style: preserve-3d;
  &:hover {
    background: transparent;
  }
  transition: all 0.3s ease-in-out;
}
.backlight::before {
  content: "";
  position: absolute;	
  inset: 0px;
  background: conic-gradient(from 90deg at 40% -25%, #00d7ff, #23c2f1, #46aee4, #699bdd, #8c87d0, #af73c3, #d25fb6, #e94aa8, #d25fb6, #af73c3, #8c87d0, #699bdd, #46aee4, #23c2f1, #00d7ff, #00d7ff);
  filter: blur(6px);
  transform: translate3d(0px,0px,-1px);
  pointer-events: none;
}

.backlight-border {
  border-radius: 12px;
  border: none;
  padding: 1px;
  background-image: conic-gradient(from 90deg at 40% -25%, #00d7ff, #23c2f1, #46aee4, #699bdd, #8c87d0, #af73c3, #d25fb6, #e94aa8, #d25fb6, #af73c3, #8c87d0, #699bdd, #46aee4, #23c2f1, #00d7ff, #00d7ff);
}




.note_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 3em;
  grid-row-gap: 3em; 
  justify-content: center;
  align-items: center;
}

.note {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;
}

.note textarea {
  white-space: pre-wrap;
  background: transparent;
  border: none;
  color: #fff;
  resize: none;
  font-size: 18px;
}

.note textarea:focus {
  outline: none;
}

.note__save {
    background: transparent;
    transition: 0.1s ease-in-out;
    border: 1.5px solid #fff;
    border-radius: 10px;
    color: #fff;
    padding: 4px 10px;
    font-size: 13px;
    cursor: pointer;
}
.note__save:hover {
    background: #fff;
    border: 1.5px solid #fff;
    color: #4b1589;
}
.note__delete {
    cursor: pointer;
}
.note__delete:hover {
    transform: scale(1.1);
}
.char__progress {
    background-color: #fff!important;
}

.note__footer {
  display: flex;
  position: absolute;
  bottom: 1px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}




:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button,
fieldset,
input {
  all: unset;
}

.DialogOverlay {
  background-color: var(--blackA9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  background-color: #2e1f35;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  color: white;
  font-weight: 500;
  font-size: 17px;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: #a0aec0;
  font-size: 15px;
  line-height: 1.5;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
  cursor: pointer;
}
.Button.violet {
  background-color: #1f2935;
  color: white;
  box-shadow: 0 2px 10px var(--blackA7);
}
.Button.violet:hover {
  background-color: var(--mauve3);
}
.Button.violet:focus {
  box-shadow: 0 0 0 2px black;
}
.Button.green {
  background-color: #1d212b;
  position: relative;
  transform-style: preserve-3d;
  &:hover {
    background: transparent;
  }
  transition: all 0.3s ease-in-out;
}
.Button.green::before {
  content: "";
  position: absolute;	
  inset: 0px;
  background: conic-gradient(from 90deg at 40% -25%, #00d7ff, #23c2f1, #46aee4, #699bdd, #8c87d0, #af73c3, #d25fb6, #e94aa8, #d25fb6, #af73c3, #8c87d0, #699bdd, #46aee4, #23c2f1, #00d7ff, #00d7ff);
  filter: blur(6px);
  transform: translate3d(0px,0px,-1px);
  pointer-events: none;
}
.Button-green-border {
  border-radius: 12px;
  border: none;
  padding: 1px;
  background-image: conic-gradient(from 90deg at 40% -25%, #00d7ff, #23c2f1, #46aee4, #699bdd, #8c87d0, #af73c3, #d25fb6, #e94aa8, #d25fb6, #af73c3, #8c87d0, #699bdd, #46aee4, #23c2f1, #00d7ff, #00d7ff);
}
.Button.green:hover {
  background-color: #2e1f35;
}

.IconButton {
  font-family: inherit;
  border-radius: 12px;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
}
.IconButton:hover {
  background-color: #1d212b;
}
.IconButton:focus {
  box-shadow: 0 0 0 2px white;
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.Label {
  font-size: 15px;
  color: white;
  width: 90px;
  text-align: right;
}

.Input {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: white;
  box-shadow: 0 0 0 1px white;
  height: 35px;
}
.Input:focus {
  box-shadow: 0 0 0 2px white;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}